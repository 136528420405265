import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import Order from '../models/Order';
import OrderItem from '../models/OrderItem';
import AvonBrochure from '../models/AvonBrochure';
import authService from '../components/api-authorization/AuthorizeService';

export interface AvonState {
    loggedInUsername: string;
    order: Order;
    currentOrderItem: OrderItem;
    orderOperationFinished: boolean;
    result: boolean | null;
    loading: number;
}

export interface UserReceivedAction { type: 'USER_RECEIVED', username: string }

export interface AddOrderRequestAction { type: 'ADD_ORDER_REQUEST' }
export interface AddOrderResponseAction { type: 'ADD_ORDER_RESPONSE', result: boolean }

export interface UpdateCurrentOrderItemAction { type: 'UPDATE_CURRENT_ORDER_ITEM', orderItem: OrderItem }

export interface AddCurrentOrderItemAction { type: 'ADD_CURRENT_ORDER_ITEM' }

export interface RemoveOrderItemAction { type: 'REMOVE_ORDER_ITEM', index: number }

export interface ClearOrderItemsAction { type: 'CLEAR_ORDER_ITEMS' }

export interface ClearResultAction { type: 'CLEAR_RESULT' }

export interface ErrorAction { type: 'ERROR' }

export type KnownAction = UserReceivedAction
    | AddOrderRequestAction | AddOrderResponseAction
    | AddCurrentOrderItemAction | UpdateCurrentOrderItemAction | RemoveOrderItemAction | ClearOrderItemsAction
    | ClearResultAction | ErrorAction;

export const actionCreators = {
    getLoggedInUser: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const user = await authService.getUser();
        if (user != null) {
            dispatch({ type: 'USER_RECEIVED', username: user.name });
        }
    },
    addOrder: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const token = await authService.getAccessToken();
        const order = getState().avon!!.order;
        fetch("api/order", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: "POST",
                body: JSON.stringify(order)
            })
            .then(response => response.json() as Promise<boolean>)
            .then(data => {
                dispatch({ type: 'ADD_ORDER_RESPONSE', result: true });
            })
            .catch(e => {
                dispatch({ type: 'ADD_ORDER_RESPONSE', result: false });
            });

        dispatch({ type: 'ADD_ORDER_REQUEST' });
    },
    updateCurrentOrderItem: (orderItem: OrderItem): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'UPDATE_CURRENT_ORDER_ITEM', orderItem: orderItem });
    },
    addCurrentOrderItem: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'ADD_CURRENT_ORDER_ITEM' });
    },
    removeOrderItem: (index: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'REMOVE_ORDER_ITEM', index: index });
    },
    clearOrderItems: () => ({ type: 'CLEAR_ORDER_ITEMS' } as ClearOrderItemsAction),
    clearResult: () => ({ type: 'CLEAR_RESULT' } as ClearResultAction),
    error: () => ({ type: 'ERROR' } as ErrorAction)
};

export const reducer: Reducer<AvonState> = (state: AvonState | undefined, incomingAction: Action): AvonState => {
    if (state === undefined) {
        return {
            loggedInUsername: "",
            order: {
                id: 0,
                ownerUsername: "",
                dateCreated: new Date(),
                items: []
            },
            currentOrderItem: {
                id: 0,
                productName: "",
                brochure: AvonBrochure.None,
                pageNumber: NaN,
                productCode: "",
                price: NaN,
                quantity: NaN
            },
            orderOperationFinished: false,
            result: null,
            loading: 0
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'USER_RECEIVED':
            return {
                ...state,
                loggedInUsername: action.username
            }
        case 'ADD_ORDER_REQUEST':
            return {
                ...state,
                loading: state.loading + 1,
                result: null,
                orderOperationFinished: false
            }
        case 'ADD_ORDER_RESPONSE':
            return {
                ...state,
                order: {
                    id: 0,
                    ownerUsername: "",
                    dateCreated: new Date(),
                    items: []
                },
                loading: state.loading - 1,
                result: action.result,
                orderOperationFinished: action.result === true
            }
        case 'UPDATE_CURRENT_ORDER_ITEM':
            return {
                ...state,
                currentOrderItem: Object.assign({}, action.orderItem)
            }
        case 'ADD_CURRENT_ORDER_ITEM':
            return {
                ...state,
                order: {
                    ...state.order,
                    items: state.order.items.concat([state.currentOrderItem])
                },
                currentOrderItem: {
                    id: 0,
                    productName: "",
                    brochure: AvonBrochure.None,
                    pageNumber: NaN,
                    productCode: "",
                    price: NaN,
                    quantity: NaN
                }
            }
        case 'REMOVE_ORDER_ITEM':
            return {
                ...state,
                order: {
                    ...state.order,
                    items: state.order.items.filter((orderItem, index) => index !== action.index)
                }
            }
        case 'CLEAR_ORDER_ITEMS':
            return {
                ...state,
                order: {
                    id: 0,
                    ownerUsername: "",
                    dateCreated: new Date(),
                    items: []
                },
                currentOrderItem: {
                    id: 0,
                    productName: "",
                    brochure: AvonBrochure.None,
                    pageNumber: NaN,
                    productCode: "",
                    price: NaN,
                    quantity: NaN
                }
            }
        case 'CLEAR_RESULT':
            return { ...state, result: null };
        case 'ERROR':
            return { ...state, result: false, loading: state.loading - 1 };
        default:
            return state;
    }
};
