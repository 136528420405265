import AvonBrochure from "../models/AvonBrochure";

export function getFriendlyNameFromBrochure(brochure: AvonBrochure): string {
    switch (brochure) {
        case AvonBrochure.AvonKatalogus:
            return "Avon katalógus";
        case AvonBrochure.AvonFocus:
            return "Avon Focus";
        case AvonBrochure.AkciosUjsag:
            return "Akciós újság";
        case AvonBrochure.EgyebAvonosFuzet:
            return "Egyéb avonos füzet";
        default:
            return "";
    }
}

export function getBrochureValueList(): AvonBrochure[] {
    const result = [];
    for (const brochure in AvonBrochure) {
        if (brochure.length <= 1) {
            continue;
        }

        result.push(AvonBrochure[brochure as keyof typeof AvonBrochure]);
    }

    return result;
}
