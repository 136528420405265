import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as AvonStore from '../store/AvonStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'reactstrap';
import OrderItemCard from "./OrderItemCard";

type NewOrderQuantityPropsType = AvonStore.AvonState
    & typeof AvonStore.actionCreators
    & RouteComponentProps<{}>;

interface NewOrderQuantityState {
    quantity: number;
    validationErrors: string[];
}

class NewOrderQuantity extends React.PureComponent<NewOrderQuantityPropsType, NewOrderQuantityState> {
    constructor(props: NewOrderQuantityPropsType) {
        super(props);

        this.state = {
            quantity: props.currentOrderItem.quantity,
            validationErrors: []
        }
    }

    componentWillMount() {
        if (isNaN(this.props.currentOrderItem.price)) {
            this.props.history.push("/new-order-price");
        }
    }

    componentWillReceiveProps(nextProps: NewOrderQuantityPropsType) {
        if (nextProps.currentOrderItem.quantity !== this.state.quantity) {
            this.setState({
                quantity: nextProps.currentOrderItem.quantity
            });
        }
    }

    public render() {
        const dropdownItems = Array.from(Array(51).keys()).slice(1);
        return (
            <React.Fragment>
                <div className={`loader ${this.props.loading ? "show" : ""}`}>
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>

                <div className="mt-3 mb-4">
                    <OrderItemCard orderItem={this.props.currentOrderItem} />
                </div>

                <h4 className="mb-3 ">Hány darabot kérsz ebből a termékből?</h4>

                <div className="form-group mt-4">
                    <div className="btn-group d-flex">
                        <button type="button" className={`btn btn-${isNaN(this.state.quantity) ? "outline-success" : "success"} dropdown-toggle d-flex justify-content-between align-items-center`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span></span>
                            <span>{isNaN(this.state.quantity) ? "Darabszám" : this.state.quantity}</span>
                        </button>
                        <div className="dropdown-menu w-100">
                            {
                                dropdownItems.map((quantity, index) => {
                                    return (
                                        <React.Fragment key={`quantity-dropdown-item-${index}`}>
                                            <span className="dropdown-item mb-2 mt-2" onClick={() => this.handleQuantityChange(quantity)}>{quantity} db</span>
                                            {index < dropdownItems.length - 1 && <hr />}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                {this.state.validationErrors.length > 0 &&
                    <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                            return (
                                <div key={`order-form-validation-error-${index}`} className="form-invalid">
                                    {error}
                                </div>
                            );
                        })}
                    </Card>
                }

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <button onClick={this.handleBackClick} type="button" className="btn btn-danger">
                        <FontAwesomeIcon icon={["fas", "arrow-left"]} className="mr-1" /> Vissza
                    </button>

                    <button onClick={this.handleNextClick} type="button" className="btn btn-success" disabled={!this.isValid()}>
                        <FontAwesomeIcon icon={["fas", "cart-plus"]} /> Hozzáadás a kosárhoz
                    </button>
                </div>
            </React.Fragment>
        );
    }

    private handleQuantityChange = (quantity: number) => {
        this.setState({
            quantity: quantity
        },
            () => this.validate());
    };

    private isValid = (): boolean => {
        return !isNaN(this.state.quantity);
    };

    private validate = () => {
        const validationErrors = [];
        if (isNaN(this.state.quantity)) {
            validationErrors.push("A darabszám megadása kötelező.");
        }

        this.setState({
            validationErrors: validationErrors
        });
    }

    private handleBackClick = () => {
        this.props.history.push("/new-order-price");
    };

    private handleNextClick = () => {
        this.props.updateCurrentOrderItem({
            ...this.props.currentOrderItem,
            quantity: this.state.quantity
        });

        this.props.addCurrentOrderItem();

        this.props.history.push("/new-order-summary");
    };
}

export default connect(
    (state: ApplicationState) => state.avon,
    AvonStore.actionCreators
)(NewOrderQuantity as any);
