import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as AvonStore from '../store/AvonStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderItemCard from "./OrderItemCard";
import { Toast, ToastBody, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';

type NewOrderSummaryPropsType = AvonStore.AvonState
    & typeof AvonStore.actionCreators
    & RouteComponentProps<{}>;

interface NewOrderSummaryState {
    result: boolean | null;
    orderOperationStarted: boolean;
    toastOpen: boolean;
}

class NewOrderSummary extends React.PureComponent<NewOrderSummaryPropsType, NewOrderSummaryState> {
    constructor(props: NewOrderSummaryPropsType) {
        super(props);

        this.state = {
            result: props.result,
            orderOperationStarted: false,
            toastOpen: props.result != null
        }

        if (props.result != null) {
            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    componentWillReceiveProps(nextProps: NewOrderSummaryPropsType) {
        if (nextProps.result !== this.props.result && nextProps.result != null) {
            this.setState({
                result: nextProps.result,
                toastOpen: true
            });

            window.setTimeout(() => {
                this.setState({
                    toastOpen: false
                }, () => {
                    this.props.clearResult();
                });
            }, 3000);
        }
    }

    componentWillMount() {
        if (this.props.order.items.length === 0 && isNaN(this.props.currentOrderItem.quantity)) {
            this.props.history.push("/new-order-quantity");
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`loader ${this.props.loading ? "show" : ""}`}>
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>

                {(this.props.loading || !this.state.orderOperationStarted) && <React.Fragment>
                    <h4 className="mb-3 ">Rendelés összegzése</h4>

                    <div className="mt-4 mb-4">
                        {this.props.order.items.map((orderItem, index) =>
                            <OrderItemCard
                                key={`order-item-card-${index}`}
                                orderItem={orderItem}
                                removeOrderItem={() => this.props.removeOrderItem(index)}
                            />
                        )}
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleNewProductClick} type="button" className="btn btn-success">
                            <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új termék
                        </button>

                        <button onClick={this.handleSendOrderClick} type="button" className="btn btn-primary" disabled={this.props.order.items.length === 0}>
                            <FontAwesomeIcon icon={["fas", "paper-plane"]} /> Rendelés elküldése
                        </button>
                    </div>
                </React.Fragment>}

                {!this.props.loading && this.state.orderOperationStarted && <React.Fragment>
                    <h4 className="mb-3 ">Köszönöm a megrendelést!</h4>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <button onClick={this.handleNewProductClick} type="button" className="btn btn-success">
                            <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" /> Új megrendelés
                        </button>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <NavLink tag={Link} to={ApplicationPaths.LogOut}>
                            <FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="fa-fw mr-1" /> Kijelentkezés
                        </NavLink>
                    </div>
                </React.Fragment>}

                <div className="toast-container">
                    <Toast isOpen={this.state.toastOpen} className={`toast-${this.state.result ? "success" : "danger"}`}>
                        <ToastBody>
                            {this.state.result ? "Sikeres művelet." : "Sikertelen művelet."}
                        </ToastBody>
                    </Toast>
                </div>
            </React.Fragment>
        );
    }

    private handleNewProductClick = () => {
        this.props.history.push("/new-order-product-name");
    };

    private handleSendOrderClick = () => {
        this.setState(({
            orderOperationStarted: true
        }));

        this.props.addOrder();
    };
}

export default connect(
    (state: ApplicationState) => state.avon,
    AvonStore.actionCreators
)(NewOrderSummary as any);
