import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import OrderItem from "../models/OrderItem";
import AvonBrochure from "../models/AvonBrochure";
import { getFriendlyNameFromBrochure } from "../utils/AvonBrochureUtils";

interface OrderItemCardProps {
    orderItem: OrderItem;
    removeOrderItem?: () => void;
}

class OrderItemCard extends React.PureComponent<OrderItemCardProps, {}> {
    public render() {
        return (
            <Card className="mb-2">
                <CardBody>
                    <CardTitle className="d-flex justify-content-between">
                        <span>{this.props.orderItem.productName}</span>
                        {this.props.removeOrderItem != null && <span onClick={this.props.removeOrderItem}><FontAwesomeIcon icon={["fas", "trash-alt"]} /></span>}
                    </CardTitle>
                    <CardText>
                        {this.props.orderItem.brochure !== AvonBrochure.None && <React.Fragment><FontAwesomeIcon icon={["fas", "newspaper"]} /> {getFriendlyNameFromBrochure(this.props.orderItem.brochure)}</React.Fragment>}
                        {!isNaN(this.props.orderItem.pageNumber) && <React.Fragment><br /><FontAwesomeIcon icon={["fas", "file"]} /> {this.props.orderItem.pageNumber}. oldal</React.Fragment>}
                        {this.props.orderItem.productCode.length > 0 && <React.Fragment><br /><FontAwesomeIcon icon={["fas", "box-open"]} /> {this.props.orderItem.productCode}</React.Fragment>}
                        {!isNaN(this.props.orderItem.price) && <React.Fragment><br /><FontAwesomeIcon icon={["fas", "dollar-sign"]} /> {this.props.orderItem.price} Ft</React.Fragment>}
                        {!isNaN(this.props.orderItem.quantity) && <React.Fragment><br /><FontAwesomeIcon icon={["fas", "th-large"]} /> {this.props.orderItem.quantity} db</React.Fragment>}
                    </CardText>
                </CardBody>
            </Card>
        );
    }
}

export default OrderItemCard;
