import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as AvonStore from '../store/AvonStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardText } from 'reactstrap';
import OrderItemCard from "./OrderItemCard";

type NewOrderPricePropsType = AvonStore.AvonState
    & typeof AvonStore.actionCreators
    & RouteComponentProps<{}>;

interface NewOrderPriceState {
    price: number;
    validationErrors: string[];
}

class NewOrderPrice extends React.PureComponent<NewOrderPricePropsType, NewOrderPriceState> {
    constructor(props: NewOrderPricePropsType) {
        super(props);

        this.state = {
            price: props.currentOrderItem.price,
            validationErrors: []
        }
    }

    componentWillMount() {
        if (this.props.currentOrderItem.productCode.length === 0) {
            this.props.history.push("/new-order-product-code");
        }
    }

    componentWillReceiveProps(nextProps: NewOrderPricePropsType) {
        if (nextProps.currentOrderItem.price !== this.state.price) {
            this.setState({
                price: nextProps.currentOrderItem.price
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`loader ${this.props.loading ? "show" : ""}`}>
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>

                <div className="mt-3 mb-4">
                    <OrderItemCard orderItem={this.props.currentOrderItem} />
                </div>

                <h4 className="mb-3 ">Mennyi a termék pontos ára?</h4>

                <Card className="mb-2">
                    <CardBody>
                        <CardText>
                            Kérlek, ne kerekíts, mert a rendszerben meg kell egyeznie a hivatalos Avon katalógusban szereplő árakkal!
                        </CardText>
                    </CardBody>
                </Card>

                <div className="form-group mt-3">
                    <label htmlFor="inputPrice">Ár</label>
                    <input type="text" className="form-control" id="inputPrice" autoComplete="off" value={isNaN(this.state.price) ? "" : this.state.price} onChange={this.handlePriceChange} autoFocus />
                </div>

                {this.state.validationErrors.length > 0 &&
                    <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                            return (
                                <div key={`order-form-validation-error-${index}`} className="form-invalid">
                                    {error}
                                </div>
                            );
                        })}
                    </Card>
                }

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <button onClick={this.handleBackClick} type="button" className="btn btn-danger">
                        <FontAwesomeIcon icon={["fas", "arrow-left"]} className="mr-1" /> Vissza
                    </button>

                    <button onClick={this.handleNextClick} type="button" className="btn btn-success" disabled={!this.isValid()}>
                        <FontAwesomeIcon icon={["fas", "arrow-right"]} /> Tovább
                    </button>
                </div>
            </React.Fragment>
        );
    }

    private handlePriceChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                price: parseInt(e.target.value)
            },
                () => this.validate());
        }
    };

    private isValid = (): boolean => {
        return !isNaN(this.state.price);
    };

    private validate = () => {
        const validationErrors = [];
        if (isNaN(this.state.price)) {
            validationErrors.push("Az ár megadása kötelező.");
        }

        this.setState({
            validationErrors: validationErrors
        });
    }

    private handleBackClick = () => {
        this.props.history.push("/new-order-product-code");
    };

    private handleNextClick = () => {
        this.props.updateCurrentOrderItem({
            ...this.props.currentOrderItem,
            price: this.state.price
        });

        this.props.history.push("/new-order-quantity");
    };
}

export default connect(
    (state: ApplicationState) => state.avon,
    AvonStore.actionCreators
)(NewOrderPrice as any);
