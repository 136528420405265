import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as AvonStore from '../store/AvonStore'
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type NavMenuProps = AvonStore.AvonState
    & typeof AvonStore.actionCreators
    & RouteComponentProps<{}>;

interface NavMenuState {
    isOpen: boolean;
}

class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {
        this.props.getLoggedInUser();
    }

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 w-100" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/" className="">Avon webshop</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} onClick={this.toggle} navbar>
                        </Collapse>
                    </Container>
                </Navbar>
                <Collapse id="navbarNav" className="width h-100" isOpen={this.state.isOpen} onClick={this.toggle}>
                    <ul className="navbar-nav h-100 d-flex flex-column align-items-center justify-content-around">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark mb-2" to="/"><FontAwesomeIcon icon={["fas", "home"]} className="fa-fw mr-1" /> Új rendelés</NavLink>
                            <a className="text-dark mb-2 nav-link" href="/Identity/Account/Manage/ChangePassword"><FontAwesomeIcon icon={["fas", "user"]} className="fa-fw mr-1" /> Jelszóváltoztatás</a>
                            <NavLink tag={Link} className="text-dark" to={ApplicationPaths.LogOut}><FontAwesomeIcon icon={["fas", "sign-out-alt"]} className="fa-fw mr-1" /> Kijelentkezés</NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state: ApplicationState) => state.avon,
    AvonStore.actionCreators
)(NavMenu as any);
