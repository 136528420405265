import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import NewOrderProductName from './components/NewOrderProductName';
import NewOrderBrochure from './components/NewOrderBrochure';
import NewOrderPageNumber from './components/NewOrderPageNumber';
import NewOrderProductCode from './components/NewOrderProductCode';
import NewOrderPrice from './components/NewOrderPrice';
import NewOrderQuantity from './components/NewOrderQuantity';
import NewOrderSummary from './components/NewOrderSummary';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import './custom.css';
import "react-datetime/css/react-datetime.css";

export default () => (
    <Layout>
        <Switch>
            <AuthorizeRoute exact path='/' component={NewOrderProductName} />
            <AuthorizeRoute exact path='/new-order-product-name' component={NewOrderProductName} />
            <AuthorizeRoute exact path='/new-order-brochure' component={NewOrderBrochure} />
            <AuthorizeRoute exact path='/new-order-page-number' component={NewOrderPageNumber} />
            <AuthorizeRoute exact path='/new-order-product-code' component={NewOrderProductCode} />
            <AuthorizeRoute exact path='/new-order-price' component={NewOrderPrice} />
            <AuthorizeRoute exact path='/new-order-quantity' component={NewOrderQuantity} />
            <AuthorizeRoute exact path='/new-order-summary' component={NewOrderSummary} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <Redirect to='/' />
        </Switch>
    </Layout>
);
