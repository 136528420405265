import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as AvonStore from '../store/AvonStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'reactstrap';
import OrderItemCard from "./OrderItemCard";

type NewOrderProductCodePropsType = AvonStore.AvonState
    & typeof AvonStore.actionCreators
    & RouteComponentProps<{}>;

interface NewOrderProductCodeState {
    productCode: string;
    validationErrors: string[];
}

class NewOrderProductCode extends React.PureComponent<NewOrderProductCodePropsType, NewOrderProductCodeState> {
    constructor(props: NewOrderProductCodePropsType) {
        super(props);

        this.state = {
            productCode: props.currentOrderItem.productCode,
            validationErrors: []
        }
    }

    componentWillMount() {
        if (isNaN(this.props.currentOrderItem.pageNumber)) {
            this.props.history.push("/new-order-page-number");
        }
    }

    componentWillReceiveProps(nextProps: NewOrderProductCodePropsType) {
        if (nextProps.currentOrderItem.productCode !== this.state.productCode) {
            this.setState({
                productCode: nextProps.currentOrderItem.productCode
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`loader ${this.props.loading ? "show" : ""}`}>
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>

                <div className="mt-3 mb-4">
                    <OrderItemCard orderItem={this.props.currentOrderItem} />
                </div>

                <h4 className="mb-3 ">Mi a termék kódja?</h4>

                <div className="form-group mt-3">
                    <label htmlFor="inputProductCode">Termékkód</label>
                    <input type="text" maxLength={5} className="form-control" id="inputProductCode" autoComplete="off" value={this.state.productCode} onChange={this.handleProductCodeChange} autoFocus />
                </div>

                {this.state.validationErrors.length > 0 &&
                    <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                            return (
                                <div key={`order-form-validation-error-${index}`} className="form-invalid">
                                    {error}
                                </div>
                            );
                        })}
                    </Card>
                }

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <button onClick={this.handleBackClick} type="button" className="btn btn-danger">
                        <FontAwesomeIcon icon={["fas", "arrow-left"]} className="mr-1" /> Vissza
                    </button>

                    <button onClick={this.handleNextClick} type="button" className="btn btn-success" disabled={!this.isValid()}>
                        <FontAwesomeIcon icon={["fas", "arrow-right"]} /> Tovább
                    </button>
                </div>
            </React.Fragment>
        );
    }

    private handleProductCodeChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            this.setState({
                productCode: e.target.value
            },
                () => this.validate());
        }
    };

    private isValid = (): boolean => {
        return this.state.productCode.length === 5;
    };

    private validate = () => {
        const validationErrors = [];
        if (this.state.productCode.length !== 5) {
            validationErrors.push("Az ötjegyű termékkód megadása kötelező.");
        }

        this.setState({
            validationErrors: validationErrors
        });
    }

    private handleBackClick = () => {
        this.props.history.push("/new-order-page-number");
    };

    private handleNextClick = () => {
        this.props.updateCurrentOrderItem({
            ...this.props.currentOrderItem,
            productCode: this.state.productCode
        });

        this.props.history.push("/new-order-price");
    };
}

export default connect(
    (state: ApplicationState) => state.avon,
    AvonStore.actionCreators
)(NewOrderProductCode as any);
