import * as AvonStore from './AvonStore';

export interface ApplicationState {
    avon: AvonStore.AvonState | undefined;
}

export const reducers = {
    avon: AvonStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
