import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as AvonStore from '../store/AvonStore';
import AvonBrochure from "../models/AvonBrochure";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'reactstrap';
import OrderItemCard from "./OrderItemCard";
import { getBrochureValueList, getFriendlyNameFromBrochure } from "../utils/AvonBrochureUtils";

type NewOrderBrochurePropsType = AvonStore.AvonState
    & typeof AvonStore.actionCreators
    & RouteComponentProps<{}>;

interface NewOrderBrochureState {
    brochure: AvonBrochure | null;
    validationErrors: string[];
}

class NewOrderBrochure extends React.PureComponent<NewOrderBrochurePropsType, NewOrderBrochureState> {
    constructor(props: NewOrderBrochurePropsType) {
        super(props);

        this.state = {
            brochure: props.currentOrderItem.brochure !== AvonBrochure.None ? props.currentOrderItem.brochure : null,
            validationErrors: []
        }
    }

    componentWillMount() {
        if (this.props.currentOrderItem.productName.length === 0) {
            this.props.history.push("/new-order-product-name");
        }
    }

    componentWillReceiveProps(nextProps: NewOrderBrochurePropsType) {
        if (nextProps.currentOrderItem.brochure !== this.state.brochure) {
            this.setState({
                brochure: nextProps.currentOrderItem.brochure
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <div className={`loader ${this.props.loading ? "show" : ""}`}>
                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>

                <div className="mt-3 mb-4">
                    <OrderItemCard orderItem={this.props.currentOrderItem} />
                </div>

                <h4 className="mb-3 ">Melyik újságban található?</h4>

                <div className="form-group mt-4">
                    <div className="btn-group d-flex">
                        <button type="button" className={`btn btn-${this.state.brochure == null ? "outline-success" : "success"} dropdown-toggle d-flex justify-content-between align-items-center`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span></span>
                            <span>{this.state.brochure == null ? "Újság" : getFriendlyNameFromBrochure(this.state.brochure)}</span>
                        </button>
                        <div className="dropdown-menu w-100">
                            {
                                getBrochureValueList().filter(x => x !== AvonBrochure.None).map((brochure, index) => {
                                    return (
                                        <React.Fragment key={`brochure-dropdown-item-${index}`}>
                                            <span className="dropdown-item mb-2 mt-2" onClick={() => this.handleBrochureChange(brochure)}>{getFriendlyNameFromBrochure(brochure)}</span>
                                            {index < getBrochureValueList().length - 1 && <hr />}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                {this.state.validationErrors.length > 0 &&
                    <Card className="mt-2 mb-2 p-2 bg-danger text-white">
                        {this.state.validationErrors.map((error, index) => {
                            return (
                                <div key={`order-form-validation-error-${index}`} className="form-invalid">
                                    {error}
                                </div>
                            );
                        })}
                    </Card>
                }

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <button onClick={this.handleBackClick} type="button" className="btn btn-danger">
                        <FontAwesomeIcon icon={["fas", "arrow-left"]} className="mr-1" /> Vissza
                    </button>

                    <button onClick={this.handleNextClick} type="button" className="btn btn-success" disabled={!this.isValid()}>
                        <FontAwesomeIcon icon={["fas", "arrow-right"]} /> Tovább
                    </button>
                </div>
            </React.Fragment>
        );
    }

    private handleBrochureChange = (brochure: AvonBrochure) => {
        this.setState({
            brochure: brochure
        }, () => this.validate());
    };

    private isValid = (): boolean => {
        return this.state.brochure != null;
    };

    private validate = () => {
        const validationErrors = [];
        if (this.state.brochure === null) {
            validationErrors.push("Az újság kiválasztása kötelező.");
        }

        this.setState({
            validationErrors: validationErrors
        });
    }

    private handleBackClick = () => {
        this.props.history.push("/new-order-product-name");
    };

    private handleNextClick = () => {
        this.props.updateCurrentOrderItem({
            ...this.props.currentOrderItem,
            brochure: this.state.brochure!!
        });

        this.props.history.push("/new-order-page-number");
    };
}

export default connect(
    (state: ApplicationState) => state.avon,
    AvonStore.actionCreators
)(NewOrderBrochure as any);
